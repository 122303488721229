import React from 'react';
import {Link} from "react-router-dom";
import "./TopMenu.scss";

function TopMenu(props) {
    return (
        <div className="top-menu">
            <Link to={{
                pathname: `/`
            }}>
                HOME
            </Link>
            <a href="https://discord.gg/skaysgaming" target="_blank">DISCORD</a>
            <a href="https://www.youtube.com/SkaysDroid" target="_blank">MOBILE</a>
            <a href="https://www.youtube.com/channel/UCBP6NWh6z05rIwwnR1oOM1w" target="_blank">EXECUTOR</a>
        </div>
    );
}

export default TopMenu;
